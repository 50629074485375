'use strict';
import { getParams } from '../helper/get-params';
import { Uuid } from './uuid';

export function TidyCookie(){
  this.tidySession = new Uuid().get();
  this.utmSource = '';
  if (document.cookie.indexOf('TidySession') === -1) {
    this.setTidySession();
  } else {
    this.tidySession = this.getCookie('TidySession');
  }
  this.setUtmCookie();
}

TidyCookie.prototype.setTidySession = function () {
  this.setNewTidyCookie('TidySession', this.tidySession);
  const firstUrl = {
    value: window.location.href,
    viewedAt: new Date().toUTCString(),
    referrer: document.referrer
  };
  this.setNewTidyCookie('TidySessionFirstUrl', JSON.stringify(firstUrl));
};

TidyCookie.prototype.setNewTidyCookie = function (cookieName, cookieContent) {
  const expiration = this.getTime();
  const cookie = `${cookieName}=${cookieContent};expires=${expiration};domain=.tidy.com;path=/;SameSite=Strict;`;

  document.cookie = cookie;
};

TidyCookie.prototype.getTime = function () {
  var CookieDate = new Date;
  CookieDate.setFullYear(CookieDate.getFullYear() +10);
  return CookieDate.toUTCString();
};

TidyCookie.prototype.setUtmCookie = function () {
  this.utmSource = getParams('utm_source');
  let expires;
  if (this.utmSource) {
    let utmTidy = {
      source: this.utmSource,
      dateTime: new Date()
    };
    let date = new Date();
    date.setTime(date.getTime() + (180 * 24 * 60 * 60 * 1000));
    expires = "; expires=" + date.toGMTString();
    document.cookie = "__utmztidy=" + JSON.stringify(utmTidy) + expires + ";path=/;domain=tidy.com";
  }
};

TidyCookie.prototype.getCookie = function (name) {
  var cookieArr = document.cookie.split(";");
  for(var i = 0; i < cookieArr.length; i++) {
    var cookiePair = cookieArr[i].split("=");
    if(name == cookiePair[0].trim()) {
      return decodeURIComponent(cookiePair[1]);
    }
  }
  return null;
}
