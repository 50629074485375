'use strict';

export function Uuid(){
}

Uuid.prototype.get = function () {
  return this.googleClosureUuid() + '-' + this.generateTimeStamp() + '-' + this.googleClosureUuid ();
};

Uuid.prototype.generateTimeStamp = function() {
  return Date.now();
};

 Uuid.prototype.googleClosureUuid = function() {
  var x = 2147483648;
  return Math.floor(Math.random() * x).toString(36) + Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);
};
