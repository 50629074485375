'use strict';
import { ENV_TIDY } from '../env/env';
import { getParams } from '../helper/get-params';
import { Uuid } from './uuid';
import $ from 'jquery';
import bowser from 'bowser';

export function TrackEvent (data) {
  this.env = ENV_TIDY;
  this.bowser = bowser;
  this.Uuid = Uuid;
  this.getParams = getParams;
  this.metaData = data.metaData;
  this.utmSource = data.utmSource;
  this.success = false;
  this.error = false;
  this.tidySession = data.tidySession;
  this.eventType = data.eventType;
}

TrackEvent.prototype.trackEvent = function(done){
  this.error = false;
  this.success = false;
  const object = {};
  object.Data = this.preapareData();
  var _this = this;
  $.ajax({
    url: 'https://v12huonu75.execute-api.us-west-2.amazonaws.com/api/sessions',
    method: 'POST',
    data: JSON.stringify(object),
    contentType: "application/json; charset=utf-8",
    dataType: 'json'
  })
  .fail(function () {
    _this.error = true;
    done();
  })
  .done(function () {
    _this.success = true;
    done();
  });
}

TrackEvent.prototype.preapareData = function (){
  const obj = {
    meta_data: this.metaData,
    tidy_session_id: this.tidySession,
    event_type: this.eventType,
    url: window.location.href,
    language: navigator.language,
    app_type: window.innerWidth < 100 ? 'mobile web' : 'desktop web',
    screen_resolution: window.innerWidth + 'x' + window.innerHeight,
    browser: bowser.name,
    os: bowser.osname,
    utm: this.utmSource,
    user_agent: navigator.userAgent
  };

  for (const key in obj) {
    if (obj.hasOwnProperty(key) && (obj[key] ===  '' || !obj[key])) {
      delete obj[key];
    }

  }

  return obj;
}
