'use strict'
import { TidyCookie } from './sessions/tidy-cookie';
import { TrackEvent } from './sessions/track-event';

const cookie = new TidyCookie();

window.addEventListener('load', () => {
  try {
    const event = new TrackEvent({
      eventType: 'PAGE_LOAD',
      tidySession: cookie.tidySession,
      metaData: 'DOORMAT - Page Load',
      utmSource: cookie.utmSource
    });
    event.trackEvent(function(){

    });
  } catch(e){
    console.log(e);
  }
});
